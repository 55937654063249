<template>
  <div class="d-flex flex-column">
    <div
        v-for="(game, index) in games"
        :key="game.ID"
        class="py-2 game-border-bottom tour-link-container"
        :class="dataSport.kindOfSport + '_border'"
    >
      <!-- Основной горизонтальный flex-контейнер -->
      <div class="d-flex align-items-center">
        <!-- Контейнер с роут-ссылкой -->
        <router-link
            :to="dataSport.kindOfSport + '/tour/' + tourid"
            class="tour-link no-underline flex-grow-1"
        >
          <div class="d-flex">
            <div class="flex-shrink-1 align-self-center text-left game-time-block">
              <div class="font-weight-normal time-start-game text-white">
                {{ game.time }}
              </div>
              <MlbGameState :state="game.state" :dataSport="dataSport"></MlbGameState>
            </div>
            <div class="pl-2 w-100">
              <div class="d-flex justify-content-center align-items-center">
                <div class="text-right w-100">
                  <div
                      class="font-weight-normal team-font-size text-white"
                      v-text="
                      $i18n.locale === 'ru'
                        ? game.team_side_1_title
                        : game.team_side_1_title_eng
                    "
                  />
                </div>
                <MlbGameScore :game="game" :dataSport="dataSport"></MlbGameScore>
                <div class="text-left w-100">
                  <div
                      class="font-weight-normal team-font-size text-white"
                      v-text="
                      $i18n.locale === 'ru'
                        ? game.team_side_2_title
                        : game.team_side_2_title_eng
                    "
                  />
                </div>
              </div>
              <div class="periods-block text-center">
                <small class="text-nowrap">{{ game.Periods }}</small>
              </div>
            </div>
          </div>
        </router-link>

        <!-- Контейнер для значка и тултипа справа от router-link -->
        <div v-if="game.reason && game.reason > 0" class="tooltip-container ml-2">
          <span class="tooltip-icon">
            <img :src="svgPath" alt="info icon" />
          </span>
          <div class="tooltip-message">
            {{ getReasonMessage(game.reason) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MlbStageGames",
  props: ["games", "tourid", "dataSport"],
  data() {
    return {
      svgPath: require("@/assets/img/info-circle.svg"),
    };
  },
  methods: {
    getReasonMessage(reason) {
      switch (reason) {
        case 1:
          return "Вся техника одной из команд уничтожена";
        case 2:
          return "Захват базы";
        case 3:
          return "Время вышло";
        default:
          return "";
      }
    },
  },
  components: {
    MlbGameScore: () =>
        import("@/components/blocks/world_of_tanks/MlbGameScore.vue"),
    MlbGameState: () =>
        import("@/components/blocks/world_of_tanks/MlbGameState.vue")
  }
};
</script>

<style scoped>
.no-underline {
  text-decoration: none;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
}

.tooltip-icon {
  cursor: default; /* Убираем указатель руки */
  color: #ccc;
  font-weight: bold;
}

.tooltip-message {
  visibility: hidden;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  white-space: nowrap;
  width: max-content;
  z-index: 10;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip-message {
  visibility: visible;
}
</style>
